export const Elearn = {
  TypeId: 1,
  Name: "E-Learning",
  Icon: "icon-elearn",
};
export const Blearn = {
  TypeId: 2,
  Name: "B-Learning",
  Icon: "icon-elearn",
};
export const Presential = {
  TypeId: 3,
  Name: "Presencial",
  Icon: "icon-presential",
};
export const ElearnSync = {
  TypeId: 4,
  Name: "E-Learning Síncrono",
  Icon: "icon-elearn",
};

export const CourseTypes = [Elearn, ElearnSync, Blearn, Presential];

export function GetTypeNameById(id) {
  for (let index = 0; index < CourseTypes.length; index++) {
    if (CourseTypes[index].TypeId === id) {
      return CourseTypes[index].Name;
    }
  }
  return "";
}

export function GetTypeIconById(id) {
  for (let index = 0; index < CourseTypes.length; index++) {
    if (CourseTypes[index].TypeId === id) {
      return CourseTypes[index].Icon;
    }
  }
  return "";
}
