const UploadHelper = {
  LoadFile(event) {
    var selectedFile = event.files[0];
    return new Promise(function (resolve, reject) {
      if (!selectedFile || selectedFile.validationErrors)
        reject({ Name: "", Base64Data: "", Length: 0 });
      var reader = new FileReader();
      reader.onload = (function () {
        return function (e) {
          var binaryData = e.target.result;
          var base64String = window.btoa(binaryData);
          resolve({
            Name: selectedFile.name,
            Length: selectedFile.size,
            Base64Data: base64String,
          });
        };
      })(selectedFile);
      reader.readAsBinaryString(selectedFile.rawFile);
    });
  },
};

export default UploadHelper;
