<template>
  <div class="custom-datetime-picker">
    <label v-if="label">{{ label }}</label>
    <kendo-datetimepicker
      :date-input="true"
      v-model="currentDate"
      format="dd-MM-yyyy HH:mm:ss"
      timeFormat="HH:mm"
      placeholder="Data"
      :disabled="disabled"
    ></kendo-datetimepicker>
  </div>
</template>

<script>
export default {
  props: {
    value: { type: String, required: false, default: "" },
    max: { type: String, required: false, default: "" },
    label: { type: String, required: false },
    disabled: { type: Boolean, required: false, default: false },
  },
  data() {
    return {
      currentDate: this.value,
    };
  },
  watch: {
    value(val) {
      this.loadTime(val);
    },
    currentDate(val) {
      this.$emit("input", val);
    },
  },
  methods: {
    loadTime(time) {
      if (time) {
        this.currentDate = time.length <= 10 ? time + " 00:00:00" : time;
      }
    },
  },
  created() {
    this.loadTime(this.value);
  },
};
</script>

<style lang="scss">
.custom-datetime-picker {
  position: relative;
  padding-top: 15px;
  display: block;
  > label {
    left: 15px;
    top: 0;
    right: auto;
    position: absolute;
    font-size: 12px;
    color: rgb(87, 89, 98);
  }
  > .k-datetimepicker {
    width: 100%;
    .k-picker-wrap {
      min-height: 40px;
      margin-bottom: 0;
      padding-left: 15px;
      padding-right: 15px;
      border-radius: 6px;
      border: 1px solid #ebedf2 !important;
      input {
        padding: 0;
        line-height: 40px;
        height: 40px;
      }
    }
  }
}
</style>
