<template>
  <nav class="page-menu">
    <div class="text-uppercase submenu-title">{{ title }}</div>
    <ul class="list-unstyled">
      <li v-for="(link, index) in menus" :key="index">
        <router-link :to="link.Path">
          <i :class="link.Icon" />
          <span class="size--s14">{{ link.Name }}</span>
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Opções" },
    menus: {},
  },
};
</script>

<style scoped lang="scss">
@import "../../styles/variables.scss";

nav {
  width: 100%;
  display: block;
  label {
    margin-bottom: 25px;
    text-transform: uppercase;
    padding: 25px 0 0 25px;
  }
  ul {
    padding: 10px 0;
    li {
      &:hover {
        background-color: $gray-200;
      }
      a {
        display: block;
        padding: 8px 0 8px 35px;
        transition: all ease 0.5s;
        &.router-link-exact-active,
        &.router-link-exact-active {
          color: $primary;
        }
        i {
          font-size: 22px;
          margin-right: 10px;
        }
        &:hover {
          text-decoration: none;
          color: $primary;
        }
      }
    }
  }
}
.page-menu {
  i {
    width: 35px;
  }
}
</style>
